import { AcquisitionType, GrowthStage, Prisma } from "@prisma/client"
import { add, format } from "date-fns"
import { isEmpty } from "ramda"

import { empty } from "@prisma/client/runtime/library"
import { z } from "zod"
import { getWeightedAverageRating } from "~/components/CompanySignalDetailPage/Tabs/Product"
import { formatRange } from "~/components/CompanySignalDetailPage/utils"
import { CompanySignalFilters } from "~/components/Filters/schemas/company"
import { GROWTH_STAGE_LABEL } from "~/components/SignalCard/GrowthBadge"
import {
  ADD_MANY_TO_LIST_LIMIT,
  ADMIN_EXPORT_LIMIT,
  COMPANY_HIGHLIGHTS_PROPS,
  FEED_PAGE_SIZE,
  SEARCH_EXPORT_LIMIT,
} from "~/consts/signals"
import { ProductSignalCount } from "~/routes/__protected/api/signals/$product/count"
import { getUserPermissions } from "~/routes/__protected/api/user/permissions"
import { AffinityContextValue } from "~/utils/context"
import { logQuery, singleLineSql } from "~/utils/db/debug"
import { QueryError } from "~/utils/db/errors"
import {
  isEmptySql,
  join,
  limitOffset,
  orderBy,
  parseApiFilter,
  quoteIdentifier,
  selectDistinctOn,
  SortingParameter,
  where,
} from "~/utils/db/queryBuilder"
import { createCsvFileDownload } from "~/utils/fileExports"
import { prisma } from "~/utils/prisma.server"
import {
  CompanySignalColumnMapping,
  GrowthToAbsoluteFieldMapping,
} from "~/utils/prisma/mappings"
import {
  getSignalFieldTitleString,
  OperatingStatus,
  OperatingStatusEnum,
  OPERATING_STATUS_LABEL,
} from "~/utils/signal"
import { sortCompanyHighlights } from "~/utils/sortHighlights"
import { FundingRoundRaw } from "../api/types"
import invariant from "../invariant"
import { capitalize } from "../string/format"
import { isNullish } from "../values"
import { countQuery } from "./savedSearches"
import { stringifyArray } from "../stringifyArray"

const { sql } = Prisma

export type GetCompanySignalsFromRequest = {
  items: Awaited<ReturnType<typeof companySignals>>
  affinity: AffinityContextValue
}

const companySignalMetricSchema = z.object({
  name: z.string(),
  value: z.number().nullable(),
  delta: z.number().nullable(),
  periodDays: z.number().nullable(),
  growth: z.number().nullable(),
})

export type CompanySignalMetric = z.infer<typeof companySignalMetricSchema>

const companyAwardSchema = z.object({
  award_name: z.string().nullable(),
  award_details: z.string().nullable(),
  award_year: z.number().nullable(),
  award_org: z.string().nullable(),
  award_link: z.string().nullable(),
  logo_url: z.string().nullable(),
})

export type CompanyAward = z.infer<typeof companyAwardSchema>

export const g2ProductSchema = z.object({
  g2_link: z.string(),
  medal_image: z.string().nullable(),
  product_id: z.number().nullable(),
  product_logo: z.string().nullable(),
  product_name: z.string().nullable(),
  rating: z.number().nullable(),
  reviews: z.number().nullable(),
  star_distribution: z.object({
    1: z.number(),
    2: z.number(),
    3: z.number(),
    4: z.number(),
    5: z.number(),
  }),
})

export type G2Product = z.infer<typeof g2ProductSchema>

const trustpilotDataSchema = z.object({
  domain: z.string().nullable(),
  verified: z.boolean().nullable(),
  logo_url: z.string().nullable(),
  name: z.string().nullable(),
  description: z.string().nullable(),
  category: z.string().nullable(),
  review_count: z.number().nullable(),
  review_aggregate: z
    .object({
      one: z.number().optional(),
      two: z.number().optional(),
      three: z.number().optional(),
      four: z.number().optional(),
      five: z.number().optional(),
    })
    .nullable(),
})

export type TrustpilotData = z.infer<typeof trustpilotDataSchema>

export const companySignalFeedItemSchema = z.object({
  id: z.string(),
  addedToList: z.boolean(),
  isFavourite: z.boolean(),
  rank: z.number().nullable(),
  name: z.string().nullable(),
  domain: z.string(),
  logoUrl: z.string().nullable(),
  industry: z.array(z.string()).nullable(),
  hqRegion: z.string().nullable(),
  descriptionShort: z.string(),
  linkedinUrl: z.string().nullable(),
  twitterUrl: z.string().nullable(),
  instagramUrl: z.string().nullable(),
  facebookUrl: z.string().nullable(),
  itunesUrl: z.string().nullable(),
  googlePlayUrl: z.string().nullable(),
  foundedYear: z.number().nullable(),
  growthStage: z.nativeEnum(GrowthStage).nullable(),
  operatingStatus: z.nativeEnum(OperatingStatusEnum).nullable(),
  totalFundingAmount: z.number().nullable(),
  lastFundingAmount: z.number().nullable(),
  lastFundingDate: z.coerce.date().nullable(),
  lastFundingType: z.string().nullable(),
  metrics: z.array(companySignalMetricSchema),
  awards: z.array(companyAwardSchema).nullable(),
  g2_data: z.array(g2ProductSchema).nullable(),
  highlights: z.array(z.string()).nullable(),
  newHighlights: z.array(z.string()).nullable().optional(),
  trustpilot_data: trustpilotDataSchema.nullable(),
  addedToIntegration: z.boolean().nullable(),
})

export type CompanySignalFeedItem = z.infer<typeof companySignalFeedItemSchema>

export interface AcquiredBy {
  id: string
  name: string
  rank: number
  month: string
  domain: string
  logoUrl: string
  metrics: CompanySignalMetric[]
  industry: string[]
  itunesUrl: any
  twitterUrl: string
  facebookUrl: string
  foundedYear: number
  growthStage: string
  linkedinUrl: string
  instagramUrl: any
  googlePlayUrl: any
  lastFundingDate: any
  lastFundingType: any
  operatingStatus: string
  descriptionShort: string
  lastFundingAmount: any
  totalFundingAmount: any
}

export interface CompanyTableItem {
  id: string
  addedToList: boolean
  isFavourite: boolean
  name: string
  news: string
  rank: number
  tags?: string[]
  acquiredBy: string | AcquiredBy
  acquiredDate: string
  acquisitionPrice: string
  acquisitions: string[] | null
  acquiredDetails: {
    price_usd: number | null
    acquired_on: string
    acquirer_id: string
    acquisition_type: AcquisitionType | null
    acquirer_organization_name: string
  } | null
  investments: { count: number; investor_id: string } | null
  annualRevenueEstimate: string
  awards: CompanyAward[] | null
  awardsCount: number
  companySize: string
  contactEmail: string
  crunchbaseUrl: string
  description: string
  domain: string
  employeeCount: number
  employeeMonthlyGrowth1: number
  employeeMonthlyGrowth2: number
  employeeMonthlyGrowth3: number
  employeeMonthlyGrowth4: number
  employeeMonthlyGrowth5: number
  employeeMonthlyGrowth6: number
  roles: string[]
  facebookUrl: string
  foundedYear: number
  founders?: string[]
  foundersCount: number
  fundingRounds: string
  fundingRoundsNEW: FundingRoundRaw[] | null
  fundingRoundsCount: number
  g2_data: G2Product[] | null
  g2_total_reviews: number
  g2_rating_avg: number
  g2_product_names: string[]
  g2_product_count: number
  googlePlayAppId: string
  googlePlayInstalls: string
  googlePlayRating: number
  googlePlayReviews: number
  googlePlayReviewsMonthlyGrowth1: number
  googlePlayReviewsMonthlyGrowth2: number
  googlePlayReviewsMonthlyGrowth3: number
  googlePlayReviewsMonthlyGrowth4: number
  googlePlayReviewsMonthlyGrowth5: number
  googlePlayReviewsMonthlyGrowth6: number
  googlePlayUrl: string
  growthStage: GrowthStage | null
  highlights: string[] | null
  hqLocation: string
  hqRegion: string
  industry?: string[]
  instagramFollowers: number
  instagramFollowersMonthlyGrowth1: number
  instagramFollowersMonthlyGrowth2: number
  instagramFollowersMonthlyGrowth3: number
  instagramFollowersMonthlyGrowth4: number
  instagramFollowersMonthlyGrowth5: number
  instagramFollowersMonthlyGrowth6: number
  instagramFollowing: number
  instagramUrl: string
  investors?: string[]
  investorsCount: number
  ipoDetails: string
  itSpend: number
  itunesAppId: string
  itunesRating: number
  itunesReviews: number
  itunesReviewsMonthlyGrowth1: number
  itunesReviewsMonthlyGrowth2: number
  itunesReviewsMonthlyGrowth3: number
  itunesReviewsMonthlyGrowth4: number
  itunesReviewsMonthlyGrowth5: number
  itunesReviewsMonthlyGrowth6: number
  itunesUrl: string
  lastFundingAmount: number
  lastFundingDate: string
  lastFundingType: string
  linkedinFollowers: number
  linkedinFollowersMonthlyGrowth1: number
  linkedinFollowersMonthlyGrowth2: number
  linkedinFollowersMonthlyGrowth3: number
  linkedinFollowersMonthlyGrowth4: number
  linkedinFollowersMonthlyGrowth5: number
  linkedinFollowersMonthlyGrowth6: number
  linkedinUrl: string
  logoUrl: string
  newHighlights: string[] | null
  operatingStatus: OperatingStatus
  otherDomains: string[]
  patentsCount: number
  phoneNumber: string
  postMoneyValuation: number
  primaryRole: string
  socialTrafficBreakdown: string
  specter_id: string
  subIndustry?: string[]
  technologies?: string[]
  technologiesCount: number
  totalAppDownloads: number
  totalAppDownloadsMonthlyGrowth1: number
  totalAppDownloadsMonthlyGrowth2: number
  totalAppDownloadsMonthlyGrowth3: number
  totalAppDownloadsMonthlyGrowth4: number
  totalAppDownloadsMonthlyGrowth5: number
  totalAppDownloadsMonthlyGrowth6: number
  totalFundingAmount: number
  trademarksCount: number
  trustpilot_data: TrustpilotData | null
  twitterFollowers: number
  twitterFollowersMonthlyGrowth1: number
  twitterFollowersMonthlyGrowth2: number
  twitterFollowersMonthlyGrowth3: number
  twitterFollowersMonthlyGrowth4: number
  twitterFollowersMonthlyGrowth5: number
  twitterFollowersMonthlyGrowth6: number
  twitterUrl: string
  webBounceRate: number
  webBounceRateMonthlyGrowth3: number
  webCountryBreakdown: string
  webOrganicSearchPercentage: number
  webPagesPerVisit: number
  webPagesPerVisitMonthlyGrowth3: number
  webPaidSearchPercentage: string
  webPopularityRank: number
  webPopularityRankMonthlyGrowth1: number
  webPopularityRankMonthlyGrowth2: number
  webPopularityRankMonthlyGrowth3: number
  webPopularityRankMonthlyGrowth4: number
  webPopularityRankMonthlyGrowth5: number
  webPopularityRankMonthlyGrowth6: number
  webSessionDuration: number
  webSessionDurationMonthlyGrowth3: number
  webSimilarWebsitesSimilarity: string
  webTrafficSources: string
  webVisits: number
  webVisitsMonthlyGrowth1: number
  webVisitsMonthlyGrowth2: number
  webVisitsMonthlyGrowth3: number
  webVisitsMonthlyGrowth4: number
  webVisitsMonthlyGrowth5: number
  webVisitsMonthlyGrowth6: number
  webTopCountry: string
  website: string
  integration?: {
    integrationKey: string
    lastActivityTime?: string
  }
  addedToIntegration: boolean | null
}

/**
 * Build the from-clause based on joining tables if certain columns are being
 * filtered for. Else don't join the table and save computation cost on not
 * joining tables.
 */
export function companySignalsFromClause({
  nameSearch,
  filters,
  organizationId,
  sorts,
  joinsOnly,
}: {
  nameSearch?: string | null
  filters: CompanySignalFilters
  organizationId?: string | null
  sorts?: SortingParameter[]
  joinsOnly?: boolean
}): Prisma.Sql {
  const fromClause: Prisma.Sql[] = [
    joinsOnly ? empty : sql`FROM company_data cd`,
  ]

  if (nameSearch || "description_search" in filters) {
    fromClause.push(
      sql`INNER JOIN company_data_search cds ON cds.company_id = cd."Specter - ID"`
    )
  }

  if ("Industry" in filters) {
    fromClause.push(
      sql`INNER JOIN company_industries_agg cdi ON cdi.company_id = cd."Specter - ID"`
    )
  }

  if ("SubIndustry" in filters) {
    fromClause.push(
      sql`INNER JOIN company_subindustries_agg cdsi ON cdsi.company_id = cd."Specter - ID"`
    )
  }

  if ("LastActivityDate" in filters || filters.inCRM === true) {
    fromClause.push(
      sql`INNER JOIN "clients_integration_companies" inte ON cd."Specter - ID" = inte."company_id"`
    )
  }

  if (
    "Awards" in filters ||
    "AwardsCount" in filters ||
    sorts?.some((s) => s.column === "awardsCount")
  ) {
    fromClause.push(
      sql`INNER JOIN company_awards_agg cag ON cag.company_id = cd."Specter - ID"`
    )
  }

  if (
    Object.keys(filters).some((field) =>
      field.toLowerCase().startsWith("g2")
    ) ||
    sorts?.some((s) => s.column.toLowerCase().startsWith("g2"))
  ) {
    fromClause.push(
      sql`INNER JOIN company_g2_products_agg cg2 ON cg2.company_id = cd."Specter - ID"`
    )
  }

  if (
    "NewHighlights" in filters ||
    "HasNewHighlights" in filters ||
    "HasNewFundingHighlights" in filters ||
    "HasNewGrowthHighlights" in filters
  ) {
    fromClause.push(
      sql`INNER JOIN company_highlights_new chn ON chn.company_id = cd."Specter - ID"
      ${
        "HasNewFundingHighlights" in filters
          ? sql`AND chn.is_funding_new is TRUE`
          : empty
      }${
        "HasNewGrowthHighlights" in filters
          ? sql`AND chn.is_growth_new is TRUE`
          : empty
      }`
    )
  }

  if ("Highlights" in filters) {
    fromClause.push(
      sql`INNER JOIN company_highlights ch ON ch.company_id = cd."Specter - ID"`
    )
  }

  if ("Investors" in filters) {
    fromClause.push(
      sql`
      INNER JOIN investor_agg ia ON ia.mongo_id = cd."Specter - ID"`
    )
  }

  if (
    Object.keys(filters).some((field) =>
      field.toLowerCase().startsWith("trustpilot")
    ) ||
    sorts?.some((s) => s.column.toLowerCase().startsWith("trustpilot"))
  ) {
    fromClause.push(
      sql`INNER JOIN companies_trustpilot ct ON ct.mongo_id = cd."Specter - ID"`
    )
  }

  if (organizationId) {
    fromClause.push(
      sql`INNER JOIN company_clients cc ON cc.company_id = cd."Specter - ID"`
    )
  }

  return join(fromClause, "\n ")
}

function nameSearchStatement(
  nameSearch: string | null | undefined
): Prisma.Sql {
  return nameSearch
    ? sql`cds.name_search @@ websearch_to_tsquery('simple',
          ${nameSearch}
          )`
    : empty
}

function organizationIdFilter(organizationId: string | null) {
  return organizationId ? sql`client_id = ${organizationId}::uuid` : empty
}

/**
 * Always make sure we secondarily sort by "Rank", if a column is all null
 * or has many nulls then the sort order will reflect the order items were
 * fetched from disk i.e. it's unstable
 */
export function _ensureSortOrderWithRank(
  sortOrder: SortingParameter[]
): SortingParameter[] {
  if (sortOrder.some((s) => s.column === "rank")) {
    const rankPosition = sortOrder.map((s) => s.column).indexOf("rank")
    return sortOrder
      .filter((s) => s.column === "rank")
      .concat(sortOrder[rankPosition])
  } else {
    return sortOrder.concat({
      column: "rank",
      direction: "asc",
    })
  }
}

/**
 * Return items for the company signals table, it includes a lot more data than the feed
 */
export async function companySignals<
  Type extends "table" | "feed" | "export" | "ids",
  Item = Type extends "table" | "export"
    ? CompanyTableItem
    : Type extends "ids"
    ? { id: string }
    : CompanySignalFeedItem
>({
  clerkUserId,
  organizationId,
  filters,
  sortOrder,
  page = 0,
  nameSearch = "",
  limit = FEED_PAGE_SIZE,
  type = "feed" as Type,
  isNew = false,
}: {
  clerkUserId: string
  organizationId: string | null
  filters: CompanySignalFilters
  sortOrder: SortingParameter[]
  page?: number
  limit?: number
  type?: Type
  nameSearch?: string
  isNew?: boolean
}): Promise<Item[]> {
  console.log({
    clerkUserId,
    organizationId,
    filters: JSON.stringify(filters),
    sortOrder,
    page,
    nameSearch,
    limit,
    type,
  })

  const user = await getUserPermissions(clerkUserId)
  invariant(user?.organizationId, "No user org")

  const offset = page * limit
  const sortOrderWithRank = _ensureSortOrderWithRank(sortOrder)
  const isInCRM =
    filters.inCRM === true || filters.LastActivityDate !== undefined
  // const isNotInCRM = filters.inCRM === false

  const quotedSortOrder = sortOrderWithRank.map((order) => {
    return {
      column: quoteIdentifier(
        (CompanySignalColumnMapping as Record<string, string>)[order.column] ??
          order.column
      ) as string,
      direction: order.direction,
    }
  })

  // Add restriction to absolute value columns when sorting by growth
  const sortedBySomeGrowth = sortOrder.find((s) => s.column.includes("Growth"))
  const extraFilter =
    sortedBySomeGrowth && sortedBySomeGrowth.direction == "asc"
      ? {
          // @ts-ignore
          [GrowthToAbsoluteFieldMapping[sortedBySomeGrowth.column]]: ["gt", 0],
        }
      : {}

  const whereClause = parseApiFilter(
    user.organizationId,
    { ...extraFilter, ...filters },
    CompanySignalColumnMapping
  )

  console.log("companySignals", { filters, whereClause })

  const addedToIntegration = isInCRM
    ? sql`inte.id is not null`
    : sql`
    SELECT EXISTS (
      SELECT id FROM clients_integration_companies uics
        WHERE uics.client_id = ${user.organizationId}::uuid
        AND uics.company_id = "Specter - ID"
    )
  `

  let selectStatement = sql`company_feed_item ("Specter - ID")
      || JSONB_BUILD_OBJECT(
        'isFavourite', is_company_signal_favourite("Specter - ID", ${clerkUserId}),
        'signalType', 'company',
        'addedToList', is_company_signal_added_to_list("Specter - ID", ${clerkUserId}),
        'addedToIntegration', (${addedToIntegration})
      )
      AS signal`

  if (type == "table") {
    selectStatement = sql`company_table_item ("Specter - ID")
        || JSONB_BUILD_OBJECT(
          'isFavourite', is_company_signal_favourite("Specter - ID", ${clerkUserId}),
          'signalType', 'company',
          'addedToList', is_company_signal_added_to_list("Specter - ID", ${clerkUserId}),
          'addedToIntegration', (${addedToIntegration})
        )
        AS signal`
  }

  if (type == "export") {
    selectStatement = sql`company_table_item ("Specter - ID") AS signal`
  }

  if (type == "ids") {
    selectStatement = sql`JSONB_BUILD_OBJECT ('id', "Specter - ID") AS signal`
  }

  const withStatement = buildQueryWithStatement(filters)

  const query = join(
    [
      withStatement,
      sql`SELECT`,
      selectStatement,
      companySignalsFromClause({
        nameSearch,
        filters,
        organizationId,
        sorts: sortOrder,
      }),
      where(
        join(
          [
            nameSearchStatement(nameSearch),
            whereClause,
            organizationIdFilter(organizationId),
            isNew ? filterNewSignals(whereClause) : empty,
            isInCRM
              ? sql`inte.client_id = ${user.organizationId}::uuid`
              : empty,
          ].filter((e) => !isEmptySql(e)),
          " AND "
        )
      ),
      orderBy(quotedSortOrder),
      limitOffset(limit, offset),
    ],
    "\n "
  )

  logQuery(query)

  try {
    const result = await prisma.$queryRaw<Array<{ signal: Item }>>(query)

    const mappedResult = result.map((r) => ({
      ...r.signal,
      // TODO: This is a temporary fix to handle the renamed highlights
      // // @ts-ignore
      // ...(r.signal.highlights && {
      //   // @ts-ignore
      //   highlights: r.signal.highlights.map(
      //     (h: keyof typeof COMPANY_HIGHLIGHTS_RENAMING) =>
      //       COMPANY_HIGHLIGHTS_RENAMING[h] ?? h
      //   ),
      // }),

      // // @ts-ignore
      // ...(r.signal.newHighlights && {
      //   // @ts-ignore
      //   newHighlights: r.signal.newHighlights.map(
      //     (h: keyof typeof COMPANY_HIGHLIGHTS_RENAMING) =>
      //       COMPANY_HIGHLIGHTS_RENAMING[h] ?? h
      //   ),
      // }),
    }))

    return mappedResult
  } catch (e) {
    throw new QueryError(
      `Cannot execute company signals table query: ${e}`,
      singleLineSql(query.sql),
      query.values,
      where
    )
  }
}

export function companyFeedCountQuery(organizationId: string | null) {
  if (organizationId) {
    return sql`SELECT COUNT(*)::int AS count
    FROM company_data cd
             JOIN company_clients cc ON "Specter - ID" = company_id
    WHERE cc.client_id = ${organizationId}::uuid
    `
  }

  return sql`SELECT COUNT(*)::int AS count
  FROM company_data cd
  `
}

export async function countIntegrationCompanySignals(
  organizationId: string | null,
  filters: CompanySignalFilters,
  nameSearch?: string | null,
  clientId?: string
): Promise<number> {
  const whereClause = parseApiFilter(
    organizationId!,
    filters,
    CompanySignalColumnMapping
  )
  const withStatement = buildQueryWithStatement(filters)

  let filteredQuery: Prisma.Sql

  // This works by deduplicating the count on "Specter - ID" and returning
  // a one-bit value (true) for every row we have. We then count that with
  // SELECT COUNT(*)::int FROM data
  filteredQuery = join(
    [
      withStatement,
      sql`SELECT COUNT(*)::int FROM (`,
      sql`SELECT DISTINCT ON ("Specter - ID") TRUE`,
      companySignalsFromClause({
        nameSearch,
        filters,
        organizationId,
      }),
      where(
        join(
          [
            nameSearchStatement(nameSearch),
            whereClause,
            organizationIdFilter(organizationId),
            sql`inte.client_id = ${clientId}::uuid`,
          ].filter((e) => !isEmptySql(e)),
          " AND "
        )
      ),
      sql`)`,
    ],
    "\n "
  )

  logQuery(filteredQuery)

  const result = await prisma.$queryRaw<Array<{ count: number }>>(filteredQuery)

  return result[0].count
}

export async function countCompanySignals(
  clientId: string,
  organizationId: string | null,
  filters: CompanySignalFilters,
  nameSearch?: string | null
): Promise<number> {
  const whereClause = parseApiFilter(
    clientId,
    filters,
    CompanySignalColumnMapping
  )

  const isInCRM =
    filters.inCRM === true || filters.LastActivityDate !== undefined
  const withStatement = buildQueryWithStatement(filters)

  let filteredQuery: Prisma.Sql

  // This works by deduplicating the count on "Specter - ID" and returning
  // a one-bit value (true) for every row we have. We then count that with
  // SELECT COUNT(*)::int FROM data
  filteredQuery = join(
    [
      withStatement,
      sql`SELECT COUNT(*)::int FROM (`,
      sql`SELECT DISTINCT ON ("Specter - ID") TRUE`,
      companySignalsFromClause({
        nameSearch,
        filters,
        organizationId,
      }),
      where(
        join(
          [
            nameSearchStatement(nameSearch),
            whereClause,
            organizationIdFilter(organizationId),
            isInCRM ? sql`inte.client_id = ${clientId}::uuid` : empty,
          ].filter((e) => !isEmptySql(e)),
          " AND "
        )
      ),
      sql`)`,
    ],
    "\n "
  )

  logQuery(filteredQuery)

  const result = await prisma.$queryRaw<Array<{ count: number }>>(filteredQuery)

  return result[0].count
}

export async function countNewCompanySignals(
  clientId: string,
  organizationId: string | null,
  filters: CompanySignalFilters,
  nameSearch?: string | null
): Promise<number> {
  const whereClause = parseApiFilter(
    clientId,
    filters,
    CompanySignalColumnMapping
  )

  const isInCRM =
    filters.inCRM === true || filters.LastActivityDate !== undefined

  const withStatement = buildQueryWithStatement(filters)

  let filteredQuery: Prisma.Sql

  // This works by deduplicating the count on "Specter - ID" and returning
  // a one-bit value (true) for every row we have. We then count that with
  // SELECT COUNT(*)::int FROM data
  filteredQuery = join(
    [
      withStatement,
      sql`SELECT COUNT(*)::int FROM (`,
      sql`SELECT DISTINCT ON ("Specter - ID") TRUE`,
      companySignalsFromClause({
        nameSearch,
        filters,
        organizationId,
      }),
      where(
        join(
          [
            nameSearchStatement(nameSearch),
            whereClause,
            organizationIdFilter(organizationId),
            isInCRM ? sql`inte.client_id = ${clientId}::uuid` : empty,
            filterNewSignals(whereClause),
          ].filter((e) => !isEmptySql(e)),
          " AND "
        )
      ),
      sql`)`,
    ],
    "\n "
  )

  logQuery(filteredQuery)

  const result = await prisma.$queryRaw<Array<{ count: number }>>(filteredQuery)

  return result[0].count
}

function filterNewSignals(whereClause: Prisma.Sql): Prisma.Sql {
  return sql`NOT EXISTS (
    SELECT 1 
    FROM tmp_company_data tcd
    WHERE 
      ${join(
        [sql`cd."Specter - ID" = tcd."Specter - ID"`, whereClause].filter(
          (e) => !isEmptySql(e)
        ),
        " AND "
      )}
  )`
}

export async function companySignalsCount(
  clientId: string,
  organizationId: string | null,
  filters: CompanySignalFilters,
  nameSearch?: string | null
): Promise<ProductSignalCount> {
  const hasWhereClause = Boolean(
    !isEmpty(filters) || nameSearch || organizationId
  )

  // Is there aren't any where conditions, just run the total and return
  if (!hasWhereClause) {
    const result = await prisma.$queryRaw<Array<{ count: number }>>(
      companyFeedCountQuery(organizationId)
    )

    return {
      pages: Math.ceil(result[0].count / FEED_PAGE_SIZE),
      total: result[0].count,
      results: result[0].count,
    }
  }

  const [results, totalRows] = await Promise.all([
    countCompanySignals(clientId, organizationId, filters, nameSearch),
    prisma.$queryRaw<Array<{ count: number }>>(
      companyFeedCountQuery(organizationId)
    ),
  ])

  return {
    pages: Math.ceil(totalRows[0].count / FEED_PAGE_SIZE),
    total: totalRows[0].count,
    results,
  }
}

export function formatCompanySignalExport(
  signals: CompanyTableItem[],
  isAdmin: boolean
): Record<string, any>[] {
  function handleAcquiredBy(acquiredBy: string | AcquiredBy) {
    if (!acquiredBy) {
      return ""
    }

    if (typeof acquiredBy == "string") {
      return acquiredBy
    }

    return (acquiredBy as AcquiredBy).name
  }

  return signals.filter(Boolean).map((signal) => {
    return {
      [getSignalFieldTitleString("specterId")]: signal.id,
      [getSignalFieldTitleString("rank")]: signal.rank,
      [getSignalFieldTitleString("companyName")]: signal.name,
      [getSignalFieldTitleString("domain")]: signal.domain,
      [getSignalFieldTitleString("website")]: signal.website,
      [getSignalFieldTitleString("otherDomains")]: stringifyArray(
        signal.otherDomains
      ),
      [getSignalFieldTitleString("foundedYear")]: signal.foundedYear,
      [getSignalFieldTitleString("operatingStatus")]: signal.operatingStatus
        ? OPERATING_STATUS_LABEL[signal.operatingStatus as OperatingStatus]
        : "",
      [getSignalFieldTitleString("growthStage")]: signal.growthStage
        ? GROWTH_STAGE_LABEL[signal.growthStage as GrowthStage]
        : "",
      [getSignalFieldTitleString("primaryRole")]: capitalize(
        signal.primaryRole ?? ""
      ),
      [getSignalFieldTitleString("roles")]: stringifyArray(
        signal.roles,
        ", ",
        capitalize
      ),
      [getSignalFieldTitleString("highlights")]: stringifyArray(
        sortCompanyHighlights(signal.highlights)?.map(
          (highlight) => COMPANY_HIGHLIGHTS_PROPS[highlight]?.label ?? ""
        ) ?? []
      ),
      [getSignalFieldTitleString("description")]: signal.description,
      [getSignalFieldTitleString("industry")]: stringifyArray(
        signal.industry,
        "; "
      ),
      [getSignalFieldTitleString("subIndustry")]: stringifyArray(
        signal.subIndustry,
        "; "
      ),
      [getSignalFieldTitleString("tags")]: stringifyArray(signal.tags),
      [getSignalFieldTitleString("hqLocation")]: signal.hqLocation,
      [getSignalFieldTitleString("hqRegion")]: signal.hqRegion,
      ...(isAdmin && {
        [getSignalFieldTitleString("crunchbaseUrl")]: signal.crunchbaseUrl,
      }),
      [getSignalFieldTitleString("totalFundingAmount")]:
        signal.totalFundingAmount,
      [getSignalFieldTitleString("lastFundingAmount")]:
        signal.lastFundingAmount,
      [getSignalFieldTitleString("lastFundingDate")]: signal?.lastFundingDate
        ? format(new Date(signal.lastFundingDate), "dd/MM/yyyy")
        : "",
      [getSignalFieldTitleString("lastFundingType")]: signal.lastFundingType,
      [getSignalFieldTitleString("postMoneyValuation")]:
        signal.postMoneyValuation,
      [getSignalFieldTitleString("fundingRoundsCount")]:
        signal.fundingRoundsCount,
      [getSignalFieldTitleString("investorsCount")]: signal.investorsCount,
      [getSignalFieldTitleString("investors")]: stringifyArray(
        signal.investors
      ),
      [getSignalFieldTitleString("fundingRounds")]: signal.fundingRounds,
      [getSignalFieldTitleString("annualRevenueEstimate")]: formatRange(
        signal.annualRevenueEstimate
      ),
      [getSignalFieldTitleString("acquiredBy")]: handleAcquiredBy(
        signal.acquiredBy
      ),
      [getSignalFieldTitleString("acquiredDate")]: signal?.acquiredDate
        ? format(new Date(signal.acquiredDate), "dd/MM/yyyy")
        : "",
      [getSignalFieldTitleString("acquisitionPrice")]: signal.acquisitionPrice,
      [getSignalFieldTitleString("ipoDetails")]: signal.ipoDetails,
      [getSignalFieldTitleString("companySize")]: signal.companySize,
      [getSignalFieldTitleString("founders")]: stringifyArray(signal.founders),
      [getSignalFieldTitleString("foundersCount")]: signal.foundersCount,
      [getSignalFieldTitleString("webVisits")]: signal.webVisits,
      [getSignalFieldTitleString("webVisitsMonthlyGrowth1")]:
        signal.webVisitsMonthlyGrowth1,
      [getSignalFieldTitleString("webVisitsMonthlyGrowth2")]:
        signal.webVisitsMonthlyGrowth2,
      [getSignalFieldTitleString("webVisitsMonthlyGrowth3")]:
        signal.webVisitsMonthlyGrowth3,
      [getSignalFieldTitleString("webVisitsMonthlyGrowth4")]:
        signal.webVisitsMonthlyGrowth4,
      [getSignalFieldTitleString("webVisitsMonthlyGrowth5")]:
        signal.webVisitsMonthlyGrowth5,
      [getSignalFieldTitleString("webVisitsMonthlyGrowth6")]:
        signal.webVisitsMonthlyGrowth6,
      [getSignalFieldTitleString("webTopCountry")]: signal.webTopCountry,
      [getSignalFieldTitleString("webCountryBreakdown")]:
        signal.webCountryBreakdown,
      [getSignalFieldTitleString("webTrafficSources")]:
        signal.webTrafficSources,
      [getSignalFieldTitleString("socialTrafficBreakdown")]:
        signal.socialTrafficBreakdown,
      [getSignalFieldTitleString("webOrganicSearchPercentage")]:
        signal.webOrganicSearchPercentage,
      [getSignalFieldTitleString("webPaidSearchPercentage")]:
        signal.webPaidSearchPercentage,
      [getSignalFieldTitleString("webBounceRate")]: signal.webBounceRate,
      [getSignalFieldTitleString("webBounceRateMonthlyGrowth3")]:
        signal.webBounceRateMonthlyGrowth3,
      [getSignalFieldTitleString("webSessionDuration")]:
        signal.webSessionDuration,
      [getSignalFieldTitleString("webSessionDurationMonthlyGrowth3")]:
        signal.webSessionDurationMonthlyGrowth3,
      [getSignalFieldTitleString("webPagesPerVisit")]: signal.webPagesPerVisit,
      [getSignalFieldTitleString("webPagesPerVisitMonthlyGrowth3")]:
        signal.webPagesPerVisitMonthlyGrowth3,
      [getSignalFieldTitleString("webSimilarWebsitesSimilarity")]:
        signal.webSimilarWebsitesSimilarity,
      [getSignalFieldTitleString("webPopularityRank")]:
        signal.webPopularityRank,
      [getSignalFieldTitleString("webPopularityRankMonthlyGrowth1")]:
        signal.webPopularityRankMonthlyGrowth1,
      [getSignalFieldTitleString("webPopularityRankMonthlyGrowth2")]:
        signal.webPopularityRankMonthlyGrowth2,
      [getSignalFieldTitleString("webPopularityRankMonthlyGrowth3")]:
        signal.webPopularityRankMonthlyGrowth3,
      [getSignalFieldTitleString("webPopularityRankMonthlyGrowth4")]:
        signal.webPopularityRankMonthlyGrowth4,
      [getSignalFieldTitleString("webPopularityRankMonthlyGrowth5")]:
        signal.webPopularityRankMonthlyGrowth5,
      [getSignalFieldTitleString("webPopularityRankMonthlyGrowth6")]:
        signal.webPopularityRankMonthlyGrowth6,
      [getSignalFieldTitleString("linkedinUrl")]: signal.linkedinUrl,
      [getSignalFieldTitleString("employeeCount")]: signal.employeeCount,
      [getSignalFieldTitleString("employeeMonthlyGrowth1")]:
        signal.employeeMonthlyGrowth1,
      [getSignalFieldTitleString("employeeMonthlyGrowth2")]:
        signal.employeeMonthlyGrowth2,
      [getSignalFieldTitleString("employeeMonthlyGrowth3")]:
        signal.employeeMonthlyGrowth3,
      [getSignalFieldTitleString("employeeMonthlyGrowth4")]:
        signal.employeeMonthlyGrowth4,
      [getSignalFieldTitleString("employeeMonthlyGrowth5")]:
        signal.employeeMonthlyGrowth5,
      [getSignalFieldTitleString("employeeMonthlyGrowth6")]:
        signal.employeeMonthlyGrowth6,
      [getSignalFieldTitleString("linkedinFollowers")]:
        signal.linkedinFollowers,
      [getSignalFieldTitleString("linkedinFollowersMonthlyGrowth1")]:
        signal.linkedinFollowersMonthlyGrowth1,
      [getSignalFieldTitleString("linkedinFollowersMonthlyGrowth2")]:
        signal.linkedinFollowersMonthlyGrowth2,
      [getSignalFieldTitleString("linkedinFollowersMonthlyGrowth3")]:
        signal.linkedinFollowersMonthlyGrowth3,
      [getSignalFieldTitleString("linkedinFollowersMonthlyGrowth4")]:
        signal.linkedinFollowersMonthlyGrowth4,
      [getSignalFieldTitleString("linkedinFollowersMonthlyGrowth5")]:
        signal.linkedinFollowersMonthlyGrowth5,
      [getSignalFieldTitleString("linkedinFollowersMonthlyGrowth6")]:
        signal.linkedinFollowersMonthlyGrowth6,
      [getSignalFieldTitleString("twitterUrl")]: signal.twitterUrl,
      [getSignalFieldTitleString("twitterFollowers")]: signal.twitterFollowers,
      [getSignalFieldTitleString("twitterFollowersMonthlyGrowth1")]:
        signal.twitterFollowersMonthlyGrowth1,
      [getSignalFieldTitleString("twitterFollowersMonthlyGrowth2")]:
        signal.twitterFollowersMonthlyGrowth2,
      [getSignalFieldTitleString("twitterFollowersMonthlyGrowth3")]:
        signal.twitterFollowersMonthlyGrowth3,
      [getSignalFieldTitleString("twitterFollowersMonthlyGrowth4")]:
        signal.twitterFollowersMonthlyGrowth4,
      [getSignalFieldTitleString("twitterFollowersMonthlyGrowth5")]:
        signal.twitterFollowersMonthlyGrowth5,
      [getSignalFieldTitleString("twitterFollowersMonthlyGrowth6")]:
        signal.twitterFollowersMonthlyGrowth6,
      [getSignalFieldTitleString("instagramUrl")]: signal.instagramUrl,
      [getSignalFieldTitleString("instagramFollowers")]:
        signal.instagramFollowers,
      [getSignalFieldTitleString("instagramFollowersMonthlyGrowth1")]:
        signal.instagramFollowersMonthlyGrowth1,
      [getSignalFieldTitleString("instagramFollowersMonthlyGrowth2")]:
        signal.instagramFollowersMonthlyGrowth2,
      [getSignalFieldTitleString("instagramFollowersMonthlyGrowth3")]:
        signal.instagramFollowersMonthlyGrowth3,
      [getSignalFieldTitleString("instagramFollowersMonthlyGrowth4")]:
        signal.instagramFollowersMonthlyGrowth4,
      [getSignalFieldTitleString("instagramFollowersMonthlyGrowth5")]:
        signal.instagramFollowersMonthlyGrowth5,
      [getSignalFieldTitleString("instagramFollowersMonthlyGrowth6")]:
        signal.instagramFollowersMonthlyGrowth6,
      [getSignalFieldTitleString("instagramFollowing")]:
        signal.instagramFollowing,
      [getSignalFieldTitleString("itunesUrl")]: signal.itunesUrl,
      [getSignalFieldTitleString("itunesAppId")]: signal.itunesAppId,
      [getSignalFieldTitleString("itunesRating")]: signal.itunesRating,
      [getSignalFieldTitleString("itunesReviews")]: signal.itunesReviews,
      [getSignalFieldTitleString("itunesReviewsMonthlyGrowth1")]:
        signal.itunesReviewsMonthlyGrowth1,
      [getSignalFieldTitleString("itunesReviewsMonthlyGrowth2")]:
        signal.itunesReviewsMonthlyGrowth2,
      [getSignalFieldTitleString("itunesReviewsMonthlyGrowth3")]:
        signal.itunesReviewsMonthlyGrowth3,
      [getSignalFieldTitleString("itunesReviewsMonthlyGrowth4")]:
        signal.itunesReviewsMonthlyGrowth4,
      [getSignalFieldTitleString("itunesReviewsMonthlyGrowth5")]:
        signal.itunesReviewsMonthlyGrowth5,
      [getSignalFieldTitleString("itunesReviewsMonthlyGrowth6")]:
        signal.itunesReviewsMonthlyGrowth6,
      [getSignalFieldTitleString("googlePlayUrl")]: signal.googlePlayUrl,
      [getSignalFieldTitleString("googlePlayAppId")]: signal.googlePlayAppId,
      [getSignalFieldTitleString("googlePlayRating")]: signal.googlePlayRating,
      [getSignalFieldTitleString("googlePlayReviews")]:
        signal.googlePlayReviews,
      [getSignalFieldTitleString("googlePlayReviewsMonthlyGrowth1")]:
        signal.googlePlayReviewsMonthlyGrowth1,
      [getSignalFieldTitleString("googlePlayReviewsMonthlyGrowth2")]:
        signal.googlePlayReviewsMonthlyGrowth2,
      [getSignalFieldTitleString("googlePlayReviewsMonthlyGrowth3")]:
        signal.googlePlayReviewsMonthlyGrowth3,
      [getSignalFieldTitleString("googlePlayReviewsMonthlyGrowth4")]:
        signal.googlePlayReviewsMonthlyGrowth4,
      [getSignalFieldTitleString("googlePlayReviewsMonthlyGrowth5")]:
        signal.googlePlayReviewsMonthlyGrowth5,
      [getSignalFieldTitleString("googlePlayReviewsMonthlyGrowth6")]:
        signal.googlePlayReviewsMonthlyGrowth6,
      [getSignalFieldTitleString("googlePlayInstalls")]:
        signal.googlePlayInstalls,
      [getSignalFieldTitleString("totalAppDownloads")]:
        signal.totalAppDownloads,
      [getSignalFieldTitleString("totalAppDownloadsMonthlyGrowth1")]:
        signal.totalAppDownloadsMonthlyGrowth1,
      [getSignalFieldTitleString("totalAppDownloadsMonthlyGrowth2")]:
        signal.totalAppDownloadsMonthlyGrowth2,
      [getSignalFieldTitleString("totalAppDownloadsMonthlyGrowth3")]:
        signal.totalAppDownloadsMonthlyGrowth3,
      [getSignalFieldTitleString("totalAppDownloadsMonthlyGrowth4")]:
        signal.totalAppDownloadsMonthlyGrowth4,
      [getSignalFieldTitleString("totalAppDownloadsMonthlyGrowth5")]:
        signal.totalAppDownloadsMonthlyGrowth5,
      [getSignalFieldTitleString("totalAppDownloadsMonthlyGrowth6")]:
        signal.totalAppDownloadsMonthlyGrowth6,
      [getSignalFieldTitleString("itSpend")]: signal.itSpend,
      [getSignalFieldTitleString("technologiesCount")]:
        signal.technologiesCount,
      [getSignalFieldTitleString("technologies")]: stringifyArray(
        signal.technologies
      ),
      [getSignalFieldTitleString("patentsCount")]: signal.patentsCount,
      [getSignalFieldTitleString("trademarksCount")]: signal.trademarksCount,
      [getSignalFieldTitleString("facebookUrl")]: signal.facebookUrl,
      [getSignalFieldTitleString("contactEmail")]: signal.contactEmail,
      [getSignalFieldTitleString("phoneNumber")]: signal.phoneNumber,
      [getSignalFieldTitleString("news")]: signal.news,
      [getSignalFieldTitleString("awards")]: !isNullish(signal.awards)
        ? JSON.stringify(signal.awards)
        : "",
      [getSignalFieldTitleString("awardsCount")]: signal.awardsCount,
      [getSignalFieldTitleString("G2JSONData")]: !isNullish(signal.g2_data)
        ? JSON.stringify(signal.g2_data)
        : "",
      [getSignalFieldTitleString("g2_rating_avg")]: signal.g2_rating_avg,
      [getSignalFieldTitleString("g2_total_reviews")]: signal.g2_total_reviews,
      [getSignalFieldTitleString("TrustpilotJSONData")]: !isNullish(
        signal.trustpilot_data
      )
        ? JSON.stringify(signal.trustpilot_data)
        : "",
      [getSignalFieldTitleString("trustpilot_data")]:
        !isNullish(signal.trustpilot_data) &&
        !isNullish(signal.trustpilot_data?.review_aggregate)
          ? getWeightedAverageRating(signal.trustpilot_data?.review_aggregate)
          : "",
      [getSignalFieldTitleString("trustpilot_data.review_count")]:
        signal.trustpilot_data?.review_count ?? "",
    }
  })
}

/**
 * Given the user to send this to, an export limit and where clause to filter
 * on fetch the data, upload the file to Supabase storage and provide a download
 * URL
 * @param clerkUserId
 * @param organizationId
 * @param filters
 * @param exportLimit
 * @param sortOrder
 */
export async function companySignalsExport(
  clerkUserId: string,
  organizationId: string | null,
  filters: CompanySignalFilters,
  sortOrder: SortingParameter[],
  nameSearch: string = "",
  exportLimit = SEARCH_EXPORT_LIMIT,
  isNew: boolean = false
): Promise<string> {
  const permission = await prisma.permissions.findFirst({
    where: { userId: clerkUserId },
    select: { isAdmin: true },
  })

  const isAdmin = permission?.isAdmin ?? false

  const signals = await companySignals({
    clerkUserId,
    organizationId,
    filters,
    sortOrder,
    nameSearch,
    type: "export",
    limit: isAdmin ? ADMIN_EXPORT_LIMIT : exportLimit,
    isNew,
  })

  return createCsvFileDownload(
    "specter-company-signals",
    clerkUserId,
    formatCompanySignalExport(signals, isAdmin)
  )
}

export async function companySignalIds(
  clerkUserId: string,
  organizationId: string | null,
  filters: CompanySignalFilters,
  isNew: boolean = false,
  sortOrder: SortingParameter[],
  signalsCountLimit = ADD_MANY_TO_LIST_LIMIT
): Promise<string[]> {
  const signals = await companySignals({
    clerkUserId,
    organizationId,
    filters,
    sortOrder,
    type: "ids",
    limit: signalsCountLimit,
    isNew,
  })

  return signals.map((signal) => signal.id)
}

export interface AffinityCompany {
  "Specter ID": string
  "Specter URL": string
  "Specter Name": string
  "Specter Website": string
  "Specter HQ Location": string
  "Specter Industry": string
  "Specter Last Funding Amount": string
  "Specter Last Funding Date": string
  "Specter Last Funding Type": string
  "Specter Total Funding Amount": string
  "Specter LinkedIn": string
}

/**
 * Given the user to send this to, an export limit and where clause to filter
 * on fetch the data, upload the file to Supabase storage and provide a download
 * URL
 * @param organizationId
 * @param filters
 * @param sortOrder
 * @param limit
 */
export async function companySignalsAffinityExport(
  organizationId: string | null,
  filters: CompanySignalFilters,
  sortOrder: SortingParameter[],
  limit = SEARCH_EXPORT_LIMIT,
  isNew: boolean = false
): Promise<AffinityCompany[]> {
  const whereClause = parseApiFilter(
    organizationId ?? "",
    filters,
    CompanySignalColumnMapping
  )
  const withStatement = buildQueryWithStatement(filters)

  const quotedSortOrder = sortOrder.map((order) => {
    return {
      column: quoteIdentifier(
        (CompanySignalColumnMapping as Record<string, string>)[order.column] ??
          order.column
      ) as string,
      direction: order.direction,
    }
  })

  const query = join(
    [
      withStatement,
      selectDistinctOn(quotedSortOrder.map((c) => c.column)),
      sql`company_affinity_item
          ("Specter - ID")
          AS signal`,
      companySignalsFromClause({ filters, organizationId, sorts: sortOrder }),
      where(
        join(
          [
            whereClause,
            organizationIdFilter(organizationId),
            isNew ? filterNewSignals(whereClause) : empty,
          ].filter((e) => !isEmptySql(e)),
          " AND "
        )
      ),
      orderBy(quotedSortOrder),
      limitOffset(limit, 0),
    ],
    "\n "
  )

  logQuery(query)

  try {
    const result = await prisma.$queryRaw<Array<{ signal: AffinityCompany }>>(
      query
    )
    return result.map((r) => r.signal)
  } catch (e) {
    throw new QueryError(
      `Cannot execute company signals table query: ${e}`,
      singleLineSql(query.sql),
      query.values,
      where
    )
  }
}

//
// Company New Signals
//
export async function companySignalsNew(
  queryByUserId: number,
  clerkUserId: string,
  sortOrder: { column: string; direction: "asc" | "desc" }[],
  page: number
) {
  const result = await prisma.queriesByUser.findFirst({
    where: {
      id: queryByUserId,
    },
    select: {
      queries: {
        select: {
          id: true,
        },
      },
    },
  })

  const quotedSortOrder = sortOrder.map((order) => {
    return {
      column: quoteIdentifier(
        (CompanySignalColumnMapping as Record<string, string>)[order.column] ??
          order.column
      ) as string,
      direction: order.direction,
    }
  })

  const queryId = result?.queries.id
  const offset = page * FEED_PAGE_SIZE

  const user = await getUserPermissions(clerkUserId)

  invariant(user?.organizationId, "No user org")

  const addedToIntegration = sql`
    SELECT EXISTS (
      SELECT * FROM clients_integration_companies uics
        WHERE uics.client_id = ${user.organizationId}::uuid
        AND uics.company_id = "Specter - ID"
    )
  `

  const query = join(
    [
      sql`--companySignalsNew
      SELECT company_feed_item("Specter - ID") ||
             JSONB_BUILD_OBJECT(
                     'isFavourite', is_company_signal_favourite("Specter - ID", ${clerkUserId}),
                     'signalType', 'company',
                     'addedToList', is_company_signal_added_to_list("Specter - ID", ${clerkUserId}),
                     'addedToIntegration', (${addedToIntegration})
             ) AS signal
      FROM queries_company_new_signals new_s
               LEFT JOIN company_data cd
                         ON new_s.company_id = cd."Specter - ID"
      WHERE query_id = ${queryId}`,
      orderBy(quotedSortOrder),
      limitOffset(FEED_PAGE_SIZE, offset),
    ],
    "\n"
  )

  const items = await prisma.$queryRaw<
    Array<{ signal: CompanySignalFeedItem }>
  >(query)
  return items.map((r) => r.signal) as CompanySignalFeedItem[]
}

export async function companySignalCountNewSignals(
  clientTag: string | null,
  clientId: string,
  searchId: number
): Promise<ProductSignalCount> {
  const result = await prisma.queriesByUser.findFirst({
    where: {
      id: Number(searchId),
    },
    select: {
      queries: {
        select: {
          id: true,
          type: true,
          query: true,
        },
      },
      newCount: true,
      fullCount: true,
    },
  })

  invariant(result)

  const total = result?.fullCount
  const results = result?.newCount

  if (
    !isNullish(results) &&
    !isNullish(total) &&
    results !== -1 &&
    total !== -1
  ) {
    return {
      pages: Math.ceil(total / FEED_PAGE_SIZE),
      total: total,
      results: results,
    }
  }

  const { fullCount, newCount, newHighlightsCount } = await countQuery({
    clientId,
    product: result.queries.type,
    query: result.queries.query,
    client: clientTag ?? null,
  })

  // Update the counts in the database
  await prisma.queriesByUser.update({
    where: {
      id: searchId,
    },
    data: {
      fullCount,
      newCount,
      ...(newHighlightsCount && { newHighlightsCount }),
    },
  })

  return {
    pages: Math.ceil(fullCount / FEED_PAGE_SIZE),
    total: fullCount,
    results: newCount,
  }
}

export const getCompanyDeliveryMonth = async () => {
  const maxMonth = await prisma.companyMonthlyCount.aggregate({
    _max: {
      deliveryMonth: true,
    },
  })

  const deliveryMonth = maxMonth._max.deliveryMonth
  invariant(deliveryMonth, "Company monthly counts data is missing")

  return add(deliveryMonth, { months: 1 })
}

export const buildQueryWithStatement = (filters: CompanySignalFilters) => {
  const withStatements = []

  if ("Investors" in filters) {
    const values = filters["Investors"]?.[1] ?? []

    withStatements.push(sql`
      investor_agg AS (
          SELECT c.mongo_id, array_agg(i.specter_investor_id) AS investors
          FROM investor_company_invested ici
          JOIN companies c ON c.organization_id = ici.organization_id
          JOIN investor i ON i.investor_id = ici.investor_id
          WHERE i.specter_investor_id = ANY(ARRAY[${join(values, ", ")}])
          GROUP BY c.mongo_id
      )`)
  }

  if (withStatements.length <= 0) {
    return empty
  }

  return sql`WITH ${join(withStatements, ", ")}`
}
